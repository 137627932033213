import React from 'react'

const CircleSvg = () => {
  return (
    <svg
      id="wwm-decor"
      viewBox="0 0 344.53 357.34"
      className="w-64 absolute right-4 -top-16  md:-top-24  lg:right-32 lg:-top-32"
    >
      <defs>
        <style></style>
        <clipPath id="clip-path" transform="translate(78.48 75.46)">
          <circle className="circ1" cx="97" cy="100" r="98"></circle>
        </clipPath>
      </defs>
      <g className="circ2">
        <line
          className="circ3"
          x1="194.76"
          y1="0.34"
          x2="0.36"
          y2="207.59"
        ></line>
        <line
          className="circ3"
          x1="199.58"
          y1="5.16"
          x2="5.18"
          y2="212.41"
        ></line>
        <line className="circ3" x1="204.4" y1="9.98" x2="10" y2="217.23"></line>
        <line
          className="circ3"
          x1="209.22"
          y1="14.8"
          x2="14.82"
          y2="222.05"
        ></line>
        <line
          className="circ3"
          x1="214.04"
          y1="19.62"
          x2="19.64"
          y2="226.87"
        ></line>
        <line
          className="circ3"
          x1="218.86"
          y1="24.44"
          x2="24.46"
          y2="231.69"
        ></line>
        <line
          className="circ3"
          x1="223.68"
          y1="29.26"
          x2="29.28"
          y2="236.51"
        ></line>
        <line
          className="circ3"
          x1="228.5"
          y1="34.08"
          x2="34.1"
          y2="241.33"
        ></line>
        <line
          className="circ3"
          x1="233.32"
          y1="38.9"
          x2="38.92"
          y2="246.15"
        ></line>
        <line
          className="circ3"
          x1="238.14"
          y1="43.72"
          x2="43.74"
          y2="250.97"
        ></line>
        <line
          className="circ3"
          x1="242.95"
          y1="48.54"
          x2="48.56"
          y2="255.78"
        ></line>
        <line
          className="circ3"
          x1="247.77"
          y1="53.36"
          x2="53.38"
          y2="260.6"
        ></line>
        <line
          className="circ3"
          x1="252.59"
          y1="58.18"
          x2="58.2"
          y2="265.42"
        ></line>
        <line
          className="circ3"
          x1="257.41"
          y1="63"
          x2="63.02"
          y2="270.24"
        ></line>
        <line
          className="circ3"
          x1="262.23"
          y1="67.82"
          x2="67.84"
          y2="275.06"
        ></line>
        <line
          className="circ3"
          x1="267.05"
          y1="72.64"
          x2="72.66"
          y2="279.88"
        ></line>
        <line
          className="circ3"
          x1="271.87"
          y1="77.46"
          x2="77.48"
          y2="284.7"
        ></line>
        <line
          className="circ3"
          x1="276.69"
          y1="82.28"
          x2="82.3"
          y2="289.52"
        ></line>
        <line
          className="circ3"
          x1="281.51"
          y1="87.1"
          x2="87.12"
          y2="294.34"
        ></line>
        <line
          className="circ3"
          x1="286.33"
          y1="91.92"
          x2="91.94"
          y2="299.16"
        ></line>
        <line
          className="circ3"
          x1="291.15"
          y1="96.74"
          x2="96.76"
          y2="303.98"
        ></line>
        <line
          className="circ3"
          x1="295.97"
          y1="101.56"
          x2="101.58"
          y2="308.8"
        ></line>
        <line
          className="circ3"
          x1="300.79"
          y1="106.37"
          x2="106.4"
          y2="313.62"
        ></line>
        <line
          className="circ3"
          x1="305.61"
          y1="111.19"
          x2="111.22"
          y2="318.44"
        ></line>
        <line
          className="circ3"
          x1="310.43"
          y1="116.01"
          x2="116.04"
          y2="323.26"
        ></line>
        <line
          className="circ3"
          x1="315.25"
          y1="120.83"
          x2="120.86"
          y2="328.08"
        ></line>
        <line
          className="circ3"
          x1="320.07"
          y1="125.65"
          x2="125.68"
          y2="332.9"
        ></line>
        <line
          className="circ3"
          x1="324.89"
          y1="130.47"
          x2="130.5"
          y2="337.72"
        ></line>
        <line
          className="circ3"
          x1="329.71"
          y1="135.29"
          x2="135.32"
          y2="342.54"
        ></line>
        <line
          className="circ3"
          x1="334.53"
          y1="140.11"
          x2="140.14"
          y2="347.36"
        ></line>
        <line
          className="circ3"
          x1="339.35"
          y1="144.93"
          x2="144.95"
          y2="352.18"
        ></line>
        <line
          className="circ3"
          x1="344.17"
          y1="149.75"
          x2="149.77"
          y2="357"
        ></line>
      </g>
    </svg>
  )
}

export default CircleSvg
